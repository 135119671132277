<template lang="pug">
  .row.align-items-center.top-bar
    div.m-l-15
      p.title {{ $t("accounts.table.columns.full_name") }}
      p.name.truncated {{ userName }}
</template>

<script>
  export default {
    props: {
      userName: {
        type: String,
        default: ""
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .truncated
    +truncated
    max-width: 400px

  p
    margin: 0

  .top-bar
    background-color: $default-purple-light
    border-radius: 2px
    height: 73px
    justify-content: space-between

    .title
      color: $default-purple
      font-size: 0.8rem
      font-weight: 600

    .name
      font-size: 1.5rem
      color: $default-black
      font-weight: 400
</style>
